import { Component, ElementRef, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth0Action, RouteData } from '@app/interfaces/route-options.interface';
import { AuthService as HumiAuthService } from '@app/services/auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { type LottiePlayer } from 'lottie-web';
import { combineLatest, Observable, timer } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

export const AUTH_ERROR = 'auth-error';

/**
 * The amount of time in milliseconds until we give the user the option to manually redirect
 */
const REDIRECT_TIMEOUT = 5000;

declare let lottie: LottiePlayer;

// This component encapsulates all behaviour related to a user's Auth0 state
// Visually the view only contains a loading Humi animation, but will perform different actions based on the action provided
@Component({
    templateUrl: './auth0.view.template.html',
    styleUrl: './auth0.style.scss',
    standalone: true,
    imports: [TranslateModule],
})
export class Auth0Component {
    isTimedOut = false;
    isRedirecting = false;

    private searchParams?: URLSearchParams;
    private auth0Action?: Auth0Action;

    constructor(
        private humiAuthService: HumiAuthService,
        private element: ElementRef,
        private injector: Injector,
        private router: Router,
        route: ActivatedRoute
    ) {
        // Starts the Humi Spinner that mimics the global loading animation
        lottie.loadAnimation({
            container: element.nativeElement,
            path: '/assets/humi-logos/loading-animation.json',
        });

        // Get the search params in case they end up consumed by Auth0 this will allow for us to still log to Sentry
        this.searchParams = new URLSearchParams(location.search);

        // After the timeout (default 5 seconds, we display the message to allow the user to manually redirect)
        timer(REDIRECT_TIMEOUT)
            .pipe(takeUntilDestroyed()) // Avoids any possible console errors about trying to perform an action on a destroyed component
            .subscribe(() => (this.isTimedOut = true));

        this.determineRoutePath(route);
    }

    /**
     * If the user initiates a redirect manually via the link in the DOM
     */
    handleRedirectClick(): void {
        // Remove the initial humi spinner and link so that the user knows we are doing something
        this.element.nativeElement.querySelector('svg')?.remove();
        this.isRedirecting = true;
        const { isLoading$, isAuthenticated$ } = this.injector.get(AuthService);
        combineLatest([isLoading$, isAuthenticated$])
            .pipe(
                take(1),
                tap(this.logToSentry.bind(this)), // Log various attributes to Sentry so we can track how often and under what circumstances this occurs
                finalize(() => {
                    // This typically should only occur on "CALLBACK" (which is why it's the default action)
                    // However, all action types are in here as a precaution
                    if (this.auth0Action === 'LOGIN') {
                        this.auth0Login();
                    } else if (this.auth0Action === 'LOGOUT') {
                        this.humiAuthService.logout();
                    } else {
                        this.router.navigate(['/']);
                    }
                })
            )
            .subscribe();
    }

    /**
     * Logs various properties to Sentry that can help with debugging why the user was not redirected automatically by Auth0
     */
    private logToSentry([isLoading, isAuthenticated]: [boolean, boolean]): void {
        Sentry.captureMessage('Manual redirect from Auth0Callback initiated by user', {
            tags: {
                auth0: true,
                'auth0.action': this.auth0Action,
                // This matches the login in app.module.ts that configures when we tell Auth0 not to redirect the user
                'auth0.skipRedirectCallback': environment.auth0
                    ? !window.location.href.startsWith(environment.auth0?.authorizationParams.redirect_uri)
                    : 'No auth0 environment config',
                'auth0.isLoading': isLoading,
                'auth0.isAuthenticated': isAuthenticated,
                // These searchParams SHOULD already be logged to sentry, but we send them here anyways since they are essential for Auth0 to automatically redirect on callback
                'auth0.search.code': this.searchParams?.has('code'),
                'auth0.search.error': this.searchParams?.has('error'),
                'auth0.search.state': this.searchParams?.has('state'),
            },
        });
    }

    /**
     * Performs an action on the view depending on which route was hit
     */
    private determineRoutePath(route: ActivatedRoute): void {
        (route.data as Observable<RouteData>).pipe(take(1)).subscribe((data) => {
            const auth0Action: Auth0Action | undefined = data.auth0Action;
            this.auth0Action = auth0Action;
            if (auth0Action === 'LOGIN') {
                this.auth0Login();
            }

            if (auth0Action === 'LOGOUT') {
                this.humiAuthService.logout();
            }

            if (auth0Action === 'CALLBACK') {
                /* This is a dummy route that Auth0 will return to immediately after a successful login.
                It intentionally does nothing.

                Auth0 uses handleRedirectCallback to try to restore the previous "appState" after a login,
                meaning it will automatically handle the redirect to the user's previous page (or '/' on first login)

                Since this route does not contain an authGuard or any logic, it gives Auth0 time to execute the callback.

                BE CAUTIOUS BEFORE ADDING ANY CODE HERE - It will likely be interrupted by Auth0's redirect, leading to possible race conditions.
                */
            }
        });
    }

    private async auth0Login(): Promise<void> {
        return this.humiAuthService.redirectToLogin({
            target: '/',
        });
    }
}
