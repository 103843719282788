import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, NotifyService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class AmeegoHumiImportService {
    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private notify: NotifyService
    ) {}

    async uploadAmeegoHumiImport(payrollId: number, file: File): Promise<void> {
        const uploadAmeegoHumiImportData = new FormData();
        uploadAmeegoHumiImportData.append('file', file);
        const url = PayrollResources.UploadAmeegoHumiImport.replace(
            ':companyId',
            this.auth.company.id.toString()
        ).replace(':payrollId', payrollId.toString());
        const uploadAmeegoHumiImportResult = await this.http.post(url, uploadAmeegoHumiImportData).toPromise();
        const attributes = uploadAmeegoHumiImportResult['data']['attributes'];
        this.notify.success(attributes?.message || 'Successfully uploaded employee incomes');
    }
}
